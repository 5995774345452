.hover-shadow {
    transition: box-shadow;
    transition-duration: 120ms;
    transition-timing-function: cubic-bezier(.23,1.11,1,.99);
    will-change: transform;
}

.hover-shadow:hover {
    box-shadow: 4px 4px 0px 0px var(--hover-shadow-color);
    transform: scale(1.01) translate(-3px, -3px);
    transform-origin: right bottom;
}
