html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (prefers-color-scheme: light) {
  html {
    color-scheme: light;
  }
  body {
    color: #16191b !important;
    background: #F9FAFA !important;;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=date] {
  -moz-appearance: textfield;
}

.noScroll::-webkit-scrollbar {
  width: 10px;
}

.noScroll::-webkit-scrollbar-track {
  border-radius: 100vh;
}

.noScroll::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 100vh;
  border: 3px solid #f6f7ed;
}

.shadow-country-input {
  --tw-shadow: 0px 4px 20px -4px rgba(0, 0, 0, .2);
  --tw-shadow-colored: 0px 4px 20px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}


@media screen and (max-device-width: 640px), screen and (max-width: 640px) {
  html, body {
    overflow: hidden;
  }

  .blur-overlay {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(32, 65, 136, .85);
    z-index: 40;
  }
}