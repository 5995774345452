.animate-bounce {
    animation: bounce 3s infinite both;
  }
  
  .animate-wobble {
    animation: wobble 3s infinite both;
  }
  
  .animate-buzz {
    animation: buzz 3s infinite both;
  }
  
  .animate-tada {
    animation: tada 3s infinite both;
  }
  
  
  @keyframes bounce{
    0%, 10%, 26.5%, 40%, 100% {
      animation-timing-function: cubic-bezier(.215,.61,.355,1);
      transform: translateZ(0);
    }
    20%, 21.5% {
      animation-timing-function: cubic-bezier(.755,.05,.855,.06);
      transform: translate3d(0,-10px,0);
    }
    35% {
      animation-timing-function: cubic-bezier(.755,.05,.855,.06);
      transform: translate3d(0,-10px,0);
    }
    42.5% {
      transform: translate3d(0,-2px,0);
    } 
  }
  
  @keyframes tada{
    0% {
      transform: scaleX(1);
    }
    10%, 5% {
      transform: scale3d(.95,.95,.95) rotate(-1.5deg);
    }
    15%, 25%, 35%, 45% {
      transform: scale3d(1.05,1.05,1.05) rotate(1.5deg);
    }
    20%, 30%, 40% {
      transform: scale3d(1.05,1.05,1.05) rotate(-1.5deg);
    }
    50% {
      transform: scaleX(1);
    }
  }
  
  @keyframes buzz {
    0% {
      transform: translateX(3px) rotate(1deg);
    }
    2.5% {
        transform: translateX(-3px) rotate(-1deg);
    }
    5% {
        transform: translateX(3px) rotate(1deg);
    }
    7.5% {
        transform: translateX(-3px) rotate(-1deg);
    }
    10% {
        transform: translateX(2px) rotate(1deg);
    }
    12.5% {
        transform: translateX(-2px) rotate(-1deg);
    }
    15% {
        transform: translateX(2px) rotate(1deg);
    }
    17.5% {
        transform: translateX(-2px) rotate(-1deg);
    }
    20% {
        transform: translateX(1px) rotate(1deg);
    }
    22.5% {
        transform: translateX(-1px) rotate(-1deg);
    }
    25% {
        transform: translateX(0px) rotate(0deg);
    }
  }
  
  @keyframes wobble {
    4% {
      transform: translateY(8px);
    }
    8% {
        transform: translateY(-6px);
    }
    12% {
        transform: translateY(4px);
    }
    16% {
        transform: translateY(-2px);
    }
    21% {
        transform: translateY(1px);
    }
    25% {
        transform: translateY(0px);
    }
  }

  .Spinner {
    position: relative;
    vertical-align: middle;
    display: none;
    border-radius: 50%;
    opacity: 0;
    border: 2px solid #ffffff;
    border-top-color: transparent;
    transition: 0.3s 0.05s;
    animation: spin 0.8s infinite linear;
    transform: none; }
    .Spinner--show {
      display: inline-block;
      opacity: 1; }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg); 
    } 
  }
  