.file-selector:hover, .file-selector:focus, .file-selector-drag {
    background-color: white !important;
  }
  
.file-selector:hover .file-selector-icon, .file-selector:focus .file-selector-icon, .file-selector-drag .file-selector-icon {
color:#384AF1 !important;
}

.file-drop {
  position: absolute;
  inset: 0;
}

.file-drop-target {
  position: absolute;
  inset: 0;
}