@tailwind base;
@tailwind components;
@tailwind utilities;

.iphone::after {
    content: "";
    background: url("/assets/images/phone_mockup_ios.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    inset: 0;
    pointer-events: none;
  }

@layer utilities {
    .mobile-preview-full {
        transform: scale(0.8);
    }
    .mobile-preview-lg {
    transform: translate(-50%, -50%) scale(0.566);
    }
    .mobile-preview-md {
    transform: translate(-50%, -50%) scale(0.5);
    }
    @media (max-width: 767px) {
    .slide-in {
        transition-property: top, opacity, height;
        transition-duration: 200ms, 200ms, 0ms;
        transition-timing-function: ease-out;
        top: 0%;
        opacity: 1;
        height: 100%;
    }
    .fade-out {
        transition-property: top, opacity, height;
        transition-duration: 0ms, 100ms, 0ms;
        transition-delay: 100ms, 0ms, 100ms;
        transition-timing-function: ease-out;
        opacity: 0;
        top: 20%;
        height: 0px;
    }
    }
}